<template>
  <div>
    <table
      style="
        width: 100%;
        border: solid 0.5px;
        border-color: grey;
        border-collapse: collapse;
      "
    >
      <thead
        style="font-size: 11px; border-bottom: 0.25px solid grey; width: 100%"
      >
        <tr style="width: 100%">
          <th style="border-right: 0.25px solid grey" :rowspan="2">Actions</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">Quantity</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">Product</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">Variety</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Class/Grade
          </th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Brand/Mark
          </th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">Pack</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Count/Size
          </th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">PHC</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">PUC</th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Inventory
          </th>
          <th style="border-right: 0.25px solid grey" :colspan="5" :rowspan="2">
            <table style="width: 100%">
              <thead>
                <tr>
                  <td :colspan="5">Supplier</td>
                </tr>
                <tr>
                  <td :colspan="5"><v-divider></v-divider></td>
                </tr>
                <tr class="ma-0 pa-0">
                  <td
                    class="text-center"
                    style="border-right: 0.25px solid grey; width: 200px"
                  >
                    Party
                  </td>
                  <td
                    class="text-center"
                    style="border-right: 0.25px solid grey; width: 70px"
                  >
                    Inco
                  </td>
                  <td
                    class="text-center"
                    style="border-right: 0.25px solid grey; width: 70px"
                  >
                    Currency
                  </td>
                  <td
                    class="text-center"
                    style="border-right: 0.25px solid grey; width: 100px"
                  >
                    Unit Price
                  </td>
                  <td class="text-center" style="width: 100px">
                    Total ({{ orderFinancialSetting.baseCurrency }})
                  </td>
                </tr>
              </thead>
            </table>
          </th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Costing <br />
            ({{ orderFinancialSetting.baseCurrency }})
          </th>

          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Sales <br />
            ({{ orderFinancialSetting.baseCurrency }})
          </th>
          <th style="border-right: 0.25px solid grey" :rowspan="2">
            Profitability <br />
            ({{ orderFinancialSetting.baseCurrency }})
          </th>
        </tr>
      </thead>
      <tbody style="font-size: 11px">
        <tr v-for="(item, index) in orderItem.orderCargoItems" :key="index">
          <td style="width: 100px">
            <v-container v-if="!applyFactoredRate" style="width: 100%">
              <v-row justify="center">
                <v-btn icon small @click="editItem(item)">
                  <v-icon small>edit</v-icon>
                </v-btn>
                <v-btn icon small color="teal" @click="cloneItem(item)">
                  <v-icon small>content_copy</v-icon>
                </v-btn>
                <v-btn icon color="red" small @click="deleteItem(index, item)">
                  <v-icon small>cancel</v-icon>
                </v-btn>
              </v-row>
            </v-container>
            <v-container v-if="applyFactoredRate" style="width: 100%">
              <v-row justify="center">
                
                <v-checkbox class="mt-1 pt-0"
                icon 
                small 
                dense 
                :hide-details="true"
                :value="index"
                v-model="checkedItems">
                </v-checkbox>
                
              </v-row>
            </v-container>
          </td>
          <td class="text-center">
            {{ item.quantity }}
          </td>
          <td class="text-center">
            <span v-if="item.product">{{ item.product.name }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.varietyProduct">{{
              item.varietyProduct.name
            }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.grade">{{ item.grade }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.mark">{{ item.mark }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.packCode">{{ item.packCode }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.countSize">{{ item.countSize }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.phc">{{ item.phc }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.puc">{{ item.puc }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.inventoryCode">{{ item.inventoryCode }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center" style="width: 200px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap">
            <span v-if="item.supplier">{{ item.supplier.name }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center" style="width: 70px">
            <span v-if="item.incoTerm">{{ item.incoTerm }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center" style="width: 70px">
            <span v-if="item.currency">{{ item.currency }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center" style="width: 100px">
            <span v-if="item.unitCost">
             - {{ getSymbol(item.currency) }}
              {{ formatNumber(item.unitCost) }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center" style="width: 100px">
            <span v-if="item.totalBaseSale">
             - {{ getSymbol(item.baseCurrency) }}
              {{ formatNumber(item.totalBaseCost) }}</span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.baseCost">
             - {{ getSymbol(item.baseCurrency) }}
              {{ formatNumber(item.baseCost) }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.baseSale">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon small class="mr-1" v-on="on" :color="item.saleType == 'FIXED'?'success': item.saleType == 'MGP'?'blue': 'orange'">
                  {{ item.saleType == 'FIXED' ? 'verified' : item.saleType == 'MGP' ? 'price_check' : 'price_change'}}
                  </v-icon>
                </template>
                <span>
                  <span v-if="item.saleType == 'FIXED'">Fixed Price</span>
                  <span v-else-if="item.saleType == 'MGP'">Minimum Guaranteed Price</span>
                  <span v-else>Estimated Consignment Price</span>
                </span>
              </v-tooltip>
              {{ getSymbol(item.baseCurrency) }}
              {{ formatNumber(item.baseSale) }}
            </span>
            <span v-else>-</span>
          </td>
          <td class="text-center">
            <span v-if="item.unitProfitability" :class="{'positive-profit': item.unitProfitability > 0, 'negative-profit': item.unitProfitability < 0}">
              {{ getSymbol(item.baseCurrency) }}
              {{ formatNumber(item.unitProfitability) }}
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr v-if="orderItem && orderItem.orderCargoItems && orderItem.orderCargoItems.length == 0">
          <td :colspan="19" class="text-center">
            <span style="font-size: 11px; color: grey">No data</span>
          </td>
        </tr>
      </tbody>
    </table>
    <v-row>
      <v-col v-if="applyFactoredRate" cols="2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn 
            x-small
            depressed
            tile
            style="width: 50px"
            v-on="on"
            color="primary" 
            @click="applyBulkRate">Apply</v-btn>
          </template>
          <span>Apply to selected</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn 
            x-small
            depressed
            tile
            style="width: 50px"
            v-on="on"
            color="edit"  
            @click="applyBulkRateAll">All</v-btn>
          </template>
          <span>Apply to all</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn 
            x-small 
            depressed
            tile
            v-on="on"
            style="width: 75px"
            color="#DC9B28" 
            @click="checkedItems = []">Clear</v-btn>
          </template>
          <span>Clear all selections</span>
        </v-tooltip>
      </v-col>
      <v-col :cols="applyFactoredRate ? 8 : 12" class="text-center">
        <v-btn
          @click="addLineItem()"
          color="primary"
          text
          rounded
          style="text-transform: none"
          ><v-icon left>add_circle_outline</v-icon> Add Line Item</v-btn
        >
      </v-col>
      <v-col v-if="applyFactoredRate" cols="2">
        <!-- <v-btn>Apply</v-btn> -->
      </v-col>
    </v-row>

    <v-dialog
      v-model="lineItemModal"
      width="900px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            <span v-if="lineItem.id">Edit</span>
            <span v-else>Add</span>
            Line Item</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="primary"
            :disabled="!lineItem.productId || !lineItem.supplierId"
            :loading="savingLineItem"
            @click="saveLineItem"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn text @click="lineItemModal = false"> X </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-list dense subheader>
            <v-subheader style="font-size: 16px"
              >Product Specification</v-subheader
            >
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-text-field
                  label="Quantity"
                  type="number"
                  hide-details
                  outlined
                  dense
                  v-model="lineItem.quantity"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.productId"
                  :items="tradedProducts"
                  hide-details
                  outlined
                  dense
                  item-text="product.name"
                  item-value="product.id"
                  placeholder=" - Select Product - "
                  clearable
                  @change="setProduct($event, lineItem)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.varietyProductId"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('variety', lineItem)"
                  item-text="name"
                  hide-details
                  outlined
                  dense
                  item-value="id"
                  placeholder=" - Select Variety - "
                  @change="setVariety($event, lineItem)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.grade"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('grade', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="Grade"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.mark"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('mark', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="Mark"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.packCode"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('packCode', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="Pack"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.countSize"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('countSize', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="Count/Size"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.phc"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('phc', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="PHC"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.puc"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('puc', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="PUC"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0">
                <v-autocomplete
                  v-model="lineItem.inventoryCode"
                  :disabled="!lineItem.productId"
                  :items="filteredValues('inventoryCode', lineItem)"
                  item-text="value"
                  item-value="value"
                  hide-details
                  outlined
                  dense
                  label="Inventory Code"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-subheader style="font-size: 16px"
              >Deal terms</v-subheader
            >
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col cols="12" sm="6" md="3" class="my-1 py-0 mx-0">
                <v-select
                  :disabled="orderFinancialSetting.type == 'FIXED'"
                  hide-details
                  v-model="lineItem.saleType"
                  label="Type"
                  dense
                  outlined
                  item-text="name"
                  item-value="value"
                  :items="[
                    { name: 'Fixed Price', value: 'FIXED' },
                    { name: 'Minimum Guaranteed Price', value: 'MGP' },
                    { name: 'Market Sales Price', value: 'MARKET_RATE' },
                  ]"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="my-1 py-0 mx-0">
                <v-autocomplete
                  v-model="lineItem.incoTerm"
                  label="Inco Term"
                  outlined
                  dense
                  clearable
                  :items="incoTerms"
                  item-text="abbreviation"
                  item-value="abbreviation"
                  hide-details
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-subheader style="font-size: 16px"
                  >Financial Information</v-subheader
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="8" md="6" class="my-1 pt-1 pb-1 mx-0">
                <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Profitability </v-list-item-title>
                      <v-list-item-subtitle>
                        Per Unit: 
                        {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.unitProfitability) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="lineItem.totalProfitability > 0 && orderFinancialSetting.baseCurrency != lineItem.currency" color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important;">
                {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                {{ formatNumber(lineItem.totalProfitability) }} / {{ getSymbol(lineItem.currency) }} 
                {{ formatNumber(Math.round((lineItem.totalProfitability / lineItem.exchangeRate) * 100) / 100) }}
                </v-chip>
                      <v-chip v-else-if="lineItem.totalProfitability > 0" color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important;">
                {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                {{ formatNumber(lineItem.totalProfitability) }}
                </v-chip>
                <v-chip v-else-if="lineItem.totalProfitability < 0 && orderFinancialSetting.baseCurrency != lineItem.currency" color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important;">
                {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                {{ formatNumber(lineItem.totalProfitability) }} / {{ getSymbol(lineItem.currency) }} 
                {{ formatNumber(Math.round((lineItem.totalProfitability / lineItem.exchangeRate) * 100) / 100) }}
                </v-chip>
                <v-chip v-else-if="lineItem.totalProfitability < 0" color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important;">
                {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                {{ formatNumber(lineItem.totalProfitability) }}
                </v-chip>
                    </v-list-item-action>
                  </v-list-item>
              </v-col>
            </v-row>
            <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="8" md="6" class="my-0">
                  <v-list-item>
                    <v-list-item-title style="font-size: 14px">
                      Costs
                    </v-list-item-title>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="8" md="6" class="my-0">
                  <v-list-item>
                    <v-list-item-title style="font-size: 14px">
                      Sales
                    </v-list-item-title>
                  </v-list-item>
                </v-col>
              </v-row>
            <v-divider></v-divider>
            <v-row class="my-2">
              <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                <v-autocomplete
                  placeholder="Supplier*"
                  :items="related.data"
                  item-text="relatedOrganisation.name"
                  item-value="relatedOrganisation.id"
                  hide-details
                  outlined
                  v-model="lineItem.supplierId"
                  clearable
                  dense
                >
                  <template v-slot:selection="data">
                    <v-row align="center">
                      <v-avatar
                        size="32"
                        :color="
                          data.item && data.item.relatedOrganisation.logo
                            ? 'white'
                            : 'secondary'
                        "
                        class="mr-2 ml-1"
                        v-if="data.item"
                      >
                        <v-img
                          v-if="data.item.relatedOrganisation.logo"
                          contain
                          :src="data.item.relatedOrganisation.logo"
                        ></v-img>
                        <h3 v-else>
                          {{ data.item.relatedOrganisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                      <span v-if="data.item.relatedOrganisation">
                        {{ data.item.relatedOrganisation.name }}
                      </span>
                    </v-row>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-action>
                      <v-avatar
                        size="32"
                        :color="
                          data.item.relatedOrganisation.logo
                            ? 'white'
                            : 'secondary'
                        "
                      >
                        <v-img
                          contain
                          v-if="data.item.relatedOrganisation.logo"
                          :src="data.item.relatedOrganisation.logo"
                        ></v-img>
                        <h3 v-else>
                          {{ data.item.relatedOrganisation.name.charAt(0) }}
                        </h3>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        data.item.relatedOrganisation.name
                      }}</v-list-item-title>
                      <v-list-item-subtitle
                        v-if="data.item.relatedOrganisation.alias"
                        >{{
                          data.item.relatedOrganisation.alias
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <!-- <v-col cols="12" sm="3" class="my-1 py-0 mx-0">
                <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                      :label="`${lineItem.baseCurrency}/${lineItem.currency}`"
                      outlined
                      dense
                      disabled
                      hide-details
                      number
                      v-model="inverseExchangeRate"
                    ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="3" class="my-1 py-0 mx-0">
                        <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                        :label="`Current Rate ${lineItem.baseCurrency}/${lineItem.currency}`"
                        outlined
                        :loading="loadingCurrency"
                        dense
                        disabled
                        hide-details
                        number
                        v-model="currentExchangeRate">
                        </v-text-field>
                  </v-col>
                <v-col cols="12" sm="3" class="my-1 py-0 mx-0">
                <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                      :label="`Applied ${lineItem.baseCurrency}/${lineItem.currency}`"
                      outlined
                      dense
                      hide-details
                      number
                      prepend-inner-icon="arrow_forward"
                      @click:prepend-inner="applyCurrentRate"
                      v-model="appliedExchangeRate" @input="handelCurrencyUpdate"
                    ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="3" class="my-1 py-0 mx-0">
                <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                      :label="`lineItem.exchangeRate`"
                      outlined
                      disabled
                      dense
                      hide-details
                      number
                      v-model="lineItem.exchangeRate"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" class="my-1 py-0 mx-0">
                <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                      :label="`currentExchangeRate`"
                      outlined
                      disabled
                      dense
                      hide-details
                      number
                      v-model="currentExchangeRate"
                    ></v-text-field>
                </v-col> -->
                </v-row>
                <v-divider></v-divider>
            <v-row class="my-2">
              <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                <v-row class="my-2">
                  <v-col cols="12" sm="4" class="mr-0 pr-0 py-0">
                    <v-autocomplete
                      label="Currency"
                      outlined
                      :disabled="lockedCurrency"
                      dense
                      hide-details
                      :items="currencies"
                      v-model="lineItem.currency"
                    >
                    <!-- <v-icon v-if="!lockedCurrency"
                      @click="lockedCurrency = true"
                      >lock_open</v-icon>
                    <v-icon v-if="lockedCurrency" 
                      @click="lockedCurrency = false"
                      >lock</v-icon> -->
                    <template v-slot:prepend-inner>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                          v-on="on"
                          v-bind="attrs"
                           @click="toggleCurrencyLock"
                           >{{ lockedCurrency ? 'lock' : 'lock_open'}}
                          </v-icon>
                        </template>
                        <span>{{ lockedCurrency ? 'Unlock' : 'Lock'}}</span>
                      </v-tooltip>
                    </template>
                  </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8" class="ml-0 pl-0 py-0">
                    <v-text-field
                    :prefix="getSymbol(lineItem.currency)"
                      label="Unit Cost*"
                      outlined
                      dense
                      hide-details
                      number
                      v-model="lineItem.unitCost"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-2" v-if="lineItem.currency != lineItem.baseCurrency">
                  <v-col cols="12" sm="4" class="mr-0 pr-0 py-0">
                    <v-autocomplete v-if="lineItem.currency != lineItem.baseCurrency"
                      disabled
                      outlined
                      label="Base currency"
                      prepend-inner-icon="lock"
                      dense
                      hide-details
                      :items="currencies"
                      v-model="lineItem.baseCurrency"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="8" class="ml-0 pl-0 py-0" v-if="lineItem.currency != lineItem.baseCurrency">
                    <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                    :prefix="getSymbol(lineItem.baseCurrency)"
                      label="Base Cost*"
                      outlined
                      dense
                      hide-details
                      number
                      v-model="lineItem.baseCost"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                <v-row class="my-2" v-if="lineItem.currency != lineItem.baseCurrency">
                  <v-col cols="12" sm="3" class="mr-0 pr-0 py-0">
                    <v-autocomplete v-if="lineItem.currency != lineItem.baseCurrency"
                      disabled
                      outlined
                      dense
                      hide-details
                      :items="currencies"
                      v-model="lineItem.currency"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="9" class="ml-0 pl-0 py-0" >
                    <v-text-field v-if="lineItem.currency != lineItem.baseCurrency"
                    :prefix="getSymbol(lineItem.currency)"
                      :label="'Reverted Unit Sales Price'"
                      outlined
                      disabled
                      dense
                      hide-details
                      number
                      v-model="revertedSalePrice"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="my-2">
                  <v-col cols="12" sm="3" class="mr-0 pr-0 py-0">
                    <v-autocomplete
                        disabled
                        outlined
                        dense
                        hide-details
                        :items="currencies"
                        v-model="orderFinancialSetting.baseCurrency"
                      ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="9" class="ml-0 pl-0 py-0">
                    <v-text-field 
                      :prefix="getSymbol(lineItem.baseCurrency)"
                        :label="'Indicative Unit Sales Price'"
                        outlined
                        dense
                        hide-details
                        number
                        v-model="lineItem.baseSale"
                      ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                
                
              </v-col> -->
              
                <!-- <v-col cols="12" sm="4" class="my-1 py-0 mx-0">
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Profitability </v-list-item-title>
                      <v-list-item-subtitle>
                        Per Unit: 
                        {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.unitProfitability) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip v-if="lineItem.totalProfitability > 0" color="#4CAF50" outlined style="background-color: rgba(76, 175, 80, 0.25) !important;">
                        {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                        {{ formatNumber(lineItem.totalProfitability) }}
                      </v-chip>
                      <v-chip v-else color="#F44336" outlined style="background-color: rgba(244, 67, 54, 0.25) !important;">
                        {{ getSymbol(orderFinancialSetting.baseCurrency) }}
                        {{ formatNumber(lineItem.totalProfitability) }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-col> -->
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Cost </v-list-item-title>
                      <v-list-item-subtitle>
                        Per Unit:  -
                        {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.baseCost) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip>
                        - {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.totalBaseCost) }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" class="my-1 py-0 mx-0">
                  <v-list-item style="height: 50px">
                    <v-list-item-content>
                      <v-list-item-title> Sales </v-list-item-title>
                      <v-list-item-subtitle>
                        Per Unit: 
                        {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.baseSale) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-chip>
                        + {{ getSymbol(lineItem.baseCurrency) }}
                        {{ formatNumber(lineItem.totalBaseSale) }}
                      </v-chip>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>

              </v-row>
            </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as CurrencyCodes from "currency-codes";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
    orderSettings: {
      type: Array,
      required: true,
    },
    orderFinancialSetting: {
      type: Object,
      required: true,
    },
    products: {
      type: Object,
      required: true,
    },
    tradedProducts: {
      type: Array,
      required: true,
    },
    tradedVariations: {
      type: Array,
      required: true,
    },
    related: {
      type: Object,
      required: true,
    },
    factoredRate: {
      type: Number,
      required: false,
    },
    applyFactoredRate: {
      type: Boolean,
      required: false,
    },
    factoredRateCurrency: {
      type: String,
      required: false
    }
  },
  data: () => ({
    currencies: CurrencyCodes.codes(),
    incoTerms: [],
    lineItemModal: false,
    lineItem: {},
    productKey: 100,
    savingLineItem: false,
    currentExchangeRate: 1,
    loadingCurrency: false,
    appliedLiveExchangeRate: false,
    revertedSalePrice: 0,
    inverseExchangeRate: 0,
    appliedExchangeRate: 0,
    decimalPrimary: 4,
    decimalSecondary: 2,
    checkedItems: [],
    lockedCurrency: false,
    baseCurrencyChecked: false,
  }),
  watch: {
    "orderFinancialSetting": {
      immediate: true,
      handler(val) {
        if(val) {
          if(!this.baseCurrencyChecked) {
            this.checkCurrency()
          }
        }
      }
    },
    "lineItem.supplierId": {
      immediate: true,
      handler(val) {
        if(val) {
          if(!this.lockedCurrency) {
            let foundItem = this.related.data.find((x) => x.relatedOrganisation.id === this.lineItem.supplierId)
            if(foundItem) {
              let currency = foundItem.currency
              if(currency !== null) {
                this.lineItem.currency = currency
              }
            }

          }
        }
      }
    },
    "lineItem.currency":{
      immediate: true,
      handler: function(val){
        if(val){
          if(val == this.orderFinancialSetting.baseCurrency){
            this.lineItem.exchangeRate = 1
            this.currentExchangeRate = 1
            this.appliedExchangeRate = 1
            // this.appliedExchangeRate = (1 / this.lineItem.exchangeRate ).toFixed(this.decimalPrimary)
          }else{
            this.lineItem.baseCost = Math.round(this.lineItem.unitCost * this.lineItem.exchangeRate*100)/100;
            this.getCurrentRate(this.lineItem.baseCurrency, this.lineItem.currency)
            this.appliedExchangeRate = (1 / this.lineItem.exchangeRate ).toFixed(this.decimalPrimary)
            
          }
        }
      }
    },
    // "lineItem.exchangeRate": {
    //   immediate: true,
    //   handler: function(val) {
    //     if(val) {
    //       this.inverseExchangeRate = Math.round((1 / this.lineItem.exchangeRate) * 100) / 100
    //     }
    //   }
    // },
    // "lineItem.exchangeRate":{
    //   immediate: true,
    //   handler: function(val){
    //     console.log(val)
    //     if(val){
    //       this.lineItem.baseCost = Math.round(this.lineItem.unitCost * this.lineItem.exchangeRate*100)/100;
    //       console.log(this.lineItem.baseCost)
    //     }
    //   }
    // },
    "lineItem.quantity": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.lineItem.totalBaseCost =
            Math.round(this.lineItem.baseCost * this.lineItem.quantity*100)/100;
          this.lineItem.totalBaseSale =  Math.round(this.lineItem.baseSale * this.lineItem.quantity*100)/100;
          this.lineItem.totalProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost) * this.lineItem.quantity*100)/100;
          this.lineItem.unitProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost)*100)/100;
        }
      },
    },
    "lineItem.baseCost": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.lineItem.totalBaseCost =
          Math.round(this.lineItem.baseCost * this.lineItem.quantity*100)/100;
          this.lineItem.totalProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost) * this.lineItem.quantity*100)/100;
          this.lineItem.unitProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost)*100)/100;
        }
      },
    },
    "lineItem.baseSale": {
      immediate: true,
      handler: function (val) {
        if (val) {
          this.lineItem.totalBaseSale =
          Math.round(this.lineItem.baseSale * this.lineItem.quantity*100)/100;
          this.lineItem.totalProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost) * this.lineItem.quantity*100)/100;
          this.lineItem.unitProfitability = Math.round((this.lineItem.baseSale - this.lineItem.baseCost)*100)/100;
          if(this.lineItem.currency !== this.lineItem.baseCurrency) {
            this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
          }
        }
      },
    },
    "lineItem.unitCost": {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (
            this.orderFinancialSetting.baseCurrency == this.lineItem.currency
          ) {
            this.lineItem.baseCost = this.lineItem.unitCost;
          } else {
            this.lineItem.baseCost = Math.round(this.lineItem.unitCost * this.lineItem.exchangeRate*100)/100;
          
          }
        }
      },
    },
  },
  async created() {
    this.incoTerms = await this.$API.getIncoTerms();
    this.checkCurrency();
    // this.factoredRate = this.factoredRate ? this.factoredRate : 1;
  },
  computed: {
    inverseCurrentRate() {
      return parseFloat((1 / this.currentExchangeRate).toFixed(this.decimalPrimary))
    },
  },
  methods: {
    checkCurrency(){
      if((this.lineItem.baseCurrency === null || !this.lineItem.baseCurrency) && this.orderFinancialSetting.baseCurrency) {
      this.lineItem.baseCurrency = this.orderFinancialSetting.baseCurrency
      this.baseCurrencyChecked = true
    } else if(this.lineItem.baseCurrency !== null) {
      this.baseCurrencyChecked = true
    }
    },
    toggleCurrencyLock() {
      this.lockedCurrency = !this.lockedCurrency
    }, 
    addLineItem(containerIndex = 1) {
      this.lineItem = {
        bookingId: this.orderItem.bookingId,
        bookingContainerId: this.orderItem.id,
        quantity: 1,
        quantityType: "CT",
        tempId: new Date().getTime(),
        varietyId: null,
        grade: null,
        puc: null,
        countSize: null,
        packCode: null,
        mark: null,
        inventoryCode: null,
        cartons: 0,
        pallets: 0,
        container: 0,
        containerIndex,
        currency: this.orderFinancialSetting.baseCurrency,
        baseCurrency: this.orderFinancialSetting.baseCurrency,
        baseCost: 0,
        baseSale: 0,
        totalBaseCost: 0,
        totalBaseSale: 0,
        unitCost: 0,
        unitPrice: 0
      };
      if (this.orderFinancialSetting.type == "FIXED") {
        this.lineItem.saleType = "FIXED";
      } else if (this.orderFinancialSetting.type == "MGP") {
        this.lineItem.saleType = "MGP";
      } else if (this.orderFinancialSetting.type == "FULL_CONSIGNMENT") {
        this.lineItem.saleType = "MARKET_RATE";
      }
      // this.orderItem.orderCargoItems.push();
      this.lineItemModal = true;
    },
    cloneItem(item) {
      let newItem = {
        ...item,
        tempId: new Date().getTime(),
      };
      delete newItem.id;
      delete newItem.createdAt;
      delete newItem.updatedAt;
      this.lineItem = newItem;
      // item.orderCargoItems.forEach((x) => {
      //   delete x.id;
      //   delete x.createdAt;
      //   delete x.updatedAt;
      //   x.orderProductSpecifications.forEach((y) => {
      //     delete y.id;
      //     delete y.orderCargoItemId;
      //     delete y.createdAt;
      //     delete y.updatedAt;
      //   });
      // });
      this.lineItemModal = true
      // this.addOrderItem(newItem);
    },
    async deleteItem(index, item) {
      if (!item.id) {
        let find = this.orderItem.orderCargoItems.findIndex(
          (x) => x.tempId == item.tempId
        );
        this.orderItem.orderCargoItems.splice(find, 1);
      } else {
        let find = this.orderItem.orderCargoItems.findIndex(
          (x) => x.id == item.id
        );
        this.orderItem.orderCargoItems.splice(find, 1);
        await this.$API.updateForecastLineItem({
          id: item.id,
          isActive: false,
          isDeleted: true,
        });
      }
    },
    editItem(item){
      this.lineItem = item
      this.lineItemModal = true
    },
    filteredValues(type, item = {}) {
      if (type == "packCode") {
        return this.tradedVariations
          .filter(
            (x) =>
              x.packCode && x.organisationProduct.productId == item.productId
          )
          .map((x) => ({
            name: x.packCode,
            value: x.packCode,
          }));
      } else if (type == "countSize") {
        let filtered = this.tradedVariations.filter(
          (x) =>
            x.countSize && x.organisationProduct.productId == item.productId
        );
        if (item.packCode) {
          filtered = filtered.filter((x) => x.packCode == item.packCode);
        }
        return filtered.map((x) => ({
          name: x.countSize,
          value: x.countSize,
        }));
      } else if (type == "variety") {
        let filtered = this.products.data.filter(
          (x) => x.parentProductId == item.productId && x.type == "Variety"
        );
        return filtered.map((x) => ({
          name: x.name,
          id: x.id,
        }));
      } else if (type == "palletType") {
        return [
          { name: "Standard", value: "S" },
          { name: "Chep", value: "C" },
          { name: "High Cube", value: "H" },
          { name: "Euro", value: "E" },
        ];
      } else {
        return this.orderSettings.filter(
          (x) => x.key === type && x.productId == item.productId
        );
      }
    },
    formatNumber(x, isCost = false) {
      if (isCost) {
        x *= -1;
      }
      if (x) {
        let result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return result;
      } else return;
    },
    getSymbol(currencyCode) {
      return getSymbolFromCurrency(currencyCode);
    },
    async saveLineItem() {
      this.savingLineItem = true;
      if(this.orderItem.id){
        if (this.lineItem.id) {
        await this.$API.updateForecastLineItem(this.lineItem);
      } else {
        let result = await this.$API.createForecastLineItem(this.lineItem);
        this.lineItem = result
        this.orderItem.orderCargoItems.push(this.lineItem);
      }
      } else {
        let findIndex = this.orderItem.orderCargoItems.findIndex(x => x.tempId == this.lineItem.tempId)
        if(findIndex > -1){
          this.orderItem.orderCargoItems[findIndex] = this.lineItem
        } else {
          this.orderItem.orderCargoItems.push(this.lineItem);
        }
      }
      
      this.savingLineItem = false;
      this.lineItemModal = false;
      this.lineItem = {};
    },
    setProduct(e, data) {
      let product = this.products.data.find((x) => x.id == e);
      data.product = product;
      data.varietyProduct = null;
      data.varietyProductId = null;
      this.productKey++;
      // this.saveLineItem(data);
    },
    setVariety(e, data) {
      data.varietyProductId = e;
      if (e) {
        let find = this.products.data.find((x) => x.id == e);
        data.varietyProduct = find;
      } else {
        data.varietyProduct = null;
      }
      // this.saveLineItem(data);
    },
    handelCurrencyUpdate() {
      this.lineItem.exchangeRate = parseFloat((1 / this.appliedExchangeRate).toFixed(this.decimalPrimary))
      this.updateBaseCost()
      this.updateBaseSale()
    },
    updateBaseCost(){
      if(this.appliedExchangeRate) {
        this.appliedLiveExchangeRate = false  
        // this.lineItem.exchangeRate = Math.round((this.appliedExchangeRate / 1) * 100)/100
        this.lineItem.baseCost = parseFloat((this.lineItem.unitCost * this.lineItem.exchangeRate).toFixed(this.decimalSecondary));
      }
      // if(this.lineItem.exchangeRate){
      //     this.appliedLiveExchangeRate = false  
      //     this.lineItem.baseCost = Math.round(this.lineItem.unitCost * this.lineItem.exchangeRate*100)/100;
      //   }
    },
    updateBaseSale(){
      // if(this.lineItem.exchangeRate){
      //     this.appliedLiveExchangeRate = false  
      //     this.lineItem.baseSale = Math.round(this.lineItem.unitCost * this.lineItem.exchangeRate*100)/100;
      //   }
      if(this.lineItem.currency !== this.lineItem.baseCurrency) {
            this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
          }
    },
    applyCurrentRate() {
      this.appliedLiveExchangeRate = true
      this.appliedExchangeRate = this.currentExchangeRate
      this.lineItem.exchangeRate = parseFloat(1 / this.currentExchangeRate)
      // this.lineItem.exchangeRate = parseFloat(1 / this.currentExchangeRate).toFixed(this.decimalPrimary)
      if(this.lineItem.currency !== this.lineItem.baseCurrency) {
            this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      }
      this.appliedExchangeRate = parseFloat((1 / this.lineItem.exchangeRate).toFixed(this.decimalPrimary))
      this.lineItem.baseCost = parseFloat((this.lineItem.unitCost * this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
    },
    async getCurrentRate(currencyFrom, currencyTo){
      if(currencyFrom && currencyTo) {

        this.loadingCurrency = true
        let rate = `${currencyFrom}/${currencyTo}`
        // let rate = `USD/ZAR`
        let res = await this.$API.getLatestExchangeRate({rate: rate})
        this.currentExchangeRate = parseFloat(res.rate.toFixed(this.decimalPrimary))
      }

      if(this.appliedLiveExchangeRate) {
        // this.lineItem.exchangeRate = parseFloat((1 / this.currentExchangeRate).toFixed(this.decimalSecondary))
        this.lineItem.exchangeRate = this.inverseCurrentRate
        this.appliedExchangeRate = this.currentExchangeRate
      }
      this.lineItem.baseCost = parseFloat((this.lineItem.unitCost * this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
      if(this.lineItem.currency !== this.lineItem.baseCurrency) {
            this.revertedSalePrice = parseFloat((this.lineItem.baseSale / this.lineItem.exchangeRate).toFixed(this.decimalSecondary))
            // this.revertedSalePrice = parseFloat((this.lineItem.baseSale * this.appliedExchangeRate).toFixed(2))
      }
      this.loadingCurrency = false

      /// Factored Rate

      /// Add costing sheet/templates to 
      // 20 default per container manage order
      // Add currency watch to company info
      // Base amount should be in base currency
      // Column next to currency for exchange rate to base
      // Add a unit of measure UON column before the currency
    },
    async applyBulkRate() {
      for(const index of this.checkedItems) {
        this.applyBulkRateToItem(index)
      } /// 
      this.$emit("closeApplyingInterface");
    },
    async applyBulkRateAll() {
      this.checkedItems = []
      let i = 0
      for(const item of this.orderItem.orderCargoItems) {
        this.checkedItems.push(i)
        this.applyBulkRateToItem(i)
        i++
      }
      i = 0
      this.$emit("closeApplyingInterface");
      // this.checkedItems = []
    },
    async applyBulkRateToItem(index) {
      this.orderItem.orderCargoItems[index].exchangeRate = 1 / this.factoredRate
      this.orderItem.orderCargoItems[index].currency =  this.factoredRateCurrency
      
        // this.orderItem.orderCargoItems[index].baseCost = Math.round(this.orderItem.orderCargoItems[index].unitCost * this.lineItem.exchangeRate*100)/100;
        if (
            this.orderFinancialSetting.baseCurrency == this.orderItem.orderCargoItems[index].currency
          ) {
            this.orderItem.orderCargoItems[index].baseCost = this.orderItem.orderCargoItems[index].unitCost;
          } else {
            this.orderItem.orderCargoItems[index].baseCost = Math.round(this.orderItem.orderCargoItems[index].unitCost * this.orderItem.orderCargoItems[index].exchangeRate*100)/100;
          
          }
        this.orderItem.orderCargoItems[index].totalBaseCost = Math.round(this.orderItem.orderCargoItems[index].baseCost * this.orderItem.orderCargoItems[index].quantity*100)/100;
        // this.applyCurrentRate()
        this.orderItem.orderCargoItems[index].totalBaseSale = Math.round(this.orderItem.orderCargoItems[index].baseSale * this.orderItem.orderCargoItems[index].quantity*100)/100;
        this.orderItem.orderCargoItems[index].unitProfitability = Math.round((this.orderItem.orderCargoItems[index].baseSale - this.orderItem.orderCargoItems[index].baseCost)*100)/100;
        // this.orderItem.orderCargoItems[index].
        // this.orderItem.orderCargoItems[index].
        // this.orderItem.orderCargoItems[index].
    }
  },
};
</script>
<style scoped>
.positive-profit {
  color: #5EF632;
}

.negative-profit {
  color: red;
}
</style>